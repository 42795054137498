import { PlausibleEvent } from 'enums/plausible'
import { useEffect } from 'react'
import usePlausible from 'utils/hooks/usePlausible'
import { useInfoProviderContext } from 'utils/providers/InfoProvider/InfoProviderContext'

import { useTranslation } from 'next-i18next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import dynamic from 'next/dynamic'
import Head from 'next/head'

import MainLayout from 'layouts/MainLayout'

import Hero from 'sections/Hero/Hero'
import Options from 'sections/Options/Options'
import PaymentSystems from 'sections/PaymentSystems/PaymentSystems'
import TradeOnPlatform from 'sections/TradeOnPlatform/TradeOnPlatform'
import WhyChoose from 'sections/WhyChoose/WhyChoose'

const DynamicRestrictedModal = dynamic(() => import('components/RestrictedModal'))

export enum MetaTypeNames {
  Description = 'Description',
  Title = 'Title',
}

export const getTitleLocation = (domain: string, name: string, type: string) => {
  return domain === 'binany24.com' ? `${name}${type}24` : `${name}${type}`
}

export default function Home({ host, referrer }: { host: string; referrer: string }) {
  const { t } = useTranslation('seo')
  const setEvent = usePlausible()

  useEffect(() => {
    setEvent(PlausibleEvent.OpenMainPage, {
      type: `user`,
      appName: 'mainLanding',
      referrer,
    })
  }, [])

  const info = useInfoProviderContext()

  if (info?.restricted === true) {
    return <DynamicRestrictedModal />
  }

  return (
    <div>
      <Head>
        {/* t(`seo:mainTitle) */}
        {/* t(`seo:mainTitle24) */}
        <title>{t(`seo:${getTitleLocation(host, 'main', MetaTypeNames.Title)}`)}</title>
        {/* t(`seo:mainDescription) */}
        {/* t(`seo:mainDescription24) */}
        <meta
          name="description"
          content={t(`seo:${getTitleLocation(host, 'main', MetaTypeNames.Description)}`)}
        />

        <meta
          property="og:title"
          content={t(`seo:${getTitleLocation(host, 'main', MetaTypeNames.Title)}`)}
        />
        <meta
          property="og:description"
          content={t(`seo:${getTitleLocation(host, 'main', MetaTypeNames.Description)}`)}
        />
        <meta property="og:image" content={`https://${host}/images/ogImages/binanyCom.png`} />
        <meta property="og:site_name" content="Binany - Online Platform for Trading" />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={`https://${host}/`} />
      </Head>
      <MainLayout>
        <Hero />
        <Options referrer={referrer} />
        <WhyChoose />
        <TradeOnPlatform />
        <PaymentSystems />
      </MainLayout>
    </div>
  )
}

export async function getServerSideProps({ locale, res }) {
  res.setHeader('Cache-Control', 'public, s-maxage=10, stale-while-revalidate=59') // fixes back/forward cache restoration
  return {
    props: {
      ...(await serverSideTranslations(locale, ['seo', 'main', 'shared', 'reviews'])),
    },
  }
}
